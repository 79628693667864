.footerlanding_layout {
  background-color: #1e213b;
  padding: 48px 63px;

  .footerlanding_body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    .item {
      display: flex;
      justify-content: space-between;
      .text {
        color: #fff;
        font-weight: 400;
      }
      .text2-underline {
        text-decoration: underline;
      }
    }
    .item1 {
      flex-direction: column;
      gap: 10px;
      flex: 3;
    }
    .item2 {
      flex: 1;
      justify-content: flex-end;
      gap: 35px;
      .icon {

        border-radius: 100px;
        background-color: #1e213b;
        color: #fff;
        width: 50px;
        height: 50px;
      }
      .icon:hover {
        border: #fff 2px solid;
        cursor: pointer;
        background-color: #1e213b;
        color: #fff;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .footerlanding_layout {
    padding: 30px 40px;
  }
  .footerlanding_body {
    .item1 {
      max-width: 700px;
    }
    .item2 {
      .icon {
      }
    }
  }
}

@media (max-width: 767px) {
  .footerlanding_layout {
    padding: 20px 20px;
    .footerlanding_body {
      flex-direction: column;
      .item2 {
        .icon {
          border: #fff 2px solid;
          border-radius: 100px;
          color: #000;
          background-color: #fff;
          width: 40px;
          height: 40px;
        }
        .icon:hover {
          cursor: pointer;
          background-color: #1e213b;
          color: #fff;
        }
      }
    }
  }
}
