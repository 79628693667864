.responsive {
  margin-left: 211px;
  &.has-lti {
    margin-left: 0;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .responsive {
    margin-left: 0px;
  }
}

@media (max-width: 767px) {
  .responsive {
    margin-left: 0px;
  }
}
