.homepageorg_layout.myaccount-page {
  margin-top: 70px;
}
.myaccount_bottom {
  display: flex;
  flex-direction: column;
  gap: 30px;
  font-size: 14px;
  line-height: 21px;

  .avatar-upload {
    display: flex;
    padding: 0 40px;
    gap: 30px;
    align-items: center;
    .avatar {
      font-size: 50px;
    }
    .text-upload {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      .text {
        color: #3c4859;
        font-weight: 700;
      }
      .upload {
      }
    }
  }
  .input,
  .dropdown {
    width: 50%;
  }
  .change-password {
    color: #186df7;
    &:hover {
      cursor: pointer;
    }
  }

  .checkbox-area {
    display: flex;
    flex-direction: column;
    gap: 2px;
    .checkbox-title {
      color: #3c4859;
      font-weight: 700;
    }
    .subtitle {
      color: #636b6f;
    }
    .checkbox {
      .checkbox-group {
        flex-direction: row;
        .checkbox-item .MuiTypography-root {
          font-family: Poppins-Regular;
          font-size: 14px;
        }
      }
    }
  }
  .two-button {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .myaccount_bottom {
    .avatar-upload {
      .avatar {
      }
      .text-upload {
        .text {
        }
        .upload {
        }
      }
    }
    .input,
    .dropdown {
      width: 60%;
    }
    .change-password {
      &:hover {
      }
    }

    .checkbox-area {
      .checkbox-title {
      }
      .subtitle {
      }
      .checkbox {
        .checkbox-group {
          .checkbox-item .MuiTypography-root {
          }
        }
      }
    }
    .two-button {
    }
  }
}

@media (max-width: 767px) {
  .myaccount_bottom {
    .avatar-upload {
      .avatar {
      }
      .text-upload {
        .text {
        }
        .upload {
        }
      }
    }
    .input,
    .dropdown {
      width: 100%;
    }
    .change-password {
      &:hover {
      }
    }

    .checkbox-area {
      .checkbox-title {
      }
      .subtitle {
      }
      .checkbox {
        .checkbox-group {
          .checkbox-item .MuiTypography-root {
          }
        }
      }
    }
    .two-button {
    }
  }
}
