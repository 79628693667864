/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

@font-face {
  font-family: "Poppins-Regular";
  src: url("../fonts/DesignerVN-Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins-ExtraBold";
  src: url("../fonts/DesignerVN-Poppins-ExtraBold.ttf");
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  font-family: Poppins-Regular;
  -ms-overflow-style: none; /* cho  Internet Explorer, Edge */
  scrollbar-width: none; /* cho Firefox */
  // overflow-y: scroll;
}
body::-webkit-scrollbar {
  display: none; /* cho Chrome, Safari, and Opera */
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.button {
  text-transform: none !important;
  &:hover {
    cursor: pointer !important;
  }
  @media (max-width: 354px){
    padding: 8px 10px !important;
  }
}

.cancel-button {
  background: #fff !important;
  border: solid 2px #e03d28 !important;
  text-transform: none !important;
  box-shadow: none !important;
  color: #e03d28 !important;
}
.cancel-button:hover {
  background-color: #c93724 !important;
  color: #fff !important;
  border-color: #c93724 !important;
}

.Box-Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  @media (max-width: 767px) {
    width: 300px;
  }
  background-color: rgb(255, 255, 255);
  border: 2px solid #000;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.5);
  padding: 16px;
}
.input-text-error {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.error-text {
  font-size: 14px;
  color: red;
}
.hidden {
  display: none;
}

.border-red {
  border: 1px solid red !important;
}

.share-button {
  position: relative;

  &:hover {
    .menu-secondary {
      display: block;
    }
  }

  .menu-secondary {
    display: none;
    position: absolute;
    right: 0px;
    box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    border-radius: 6px;
    min-width: 150px;
    background-color: #fff;
    div {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 6px 10px;
      &:hover {
        cursor: pointer;
        background-color: #ccc;
      }
    }
  }
}
