.announcements_page {
  position: relative;
  font-family: Poppins-Regular;
  .back {
    margin-top: 45px;
    height: 50px;
    position: fixed;
    background-color: #fff;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding: 10px 25px;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);

    .button-back {
      text-transform: none;
      background-color: #fff;
      color: #3c4859;
      font-size: 14px;
      font-weight: 700;
      box-shadow: none;
    }
    .button-back:hover {
      background-color: #e7e9ee;
    }
  }

  .announcements_content {
  }

  .announcements_empty {
    margin-top: 95px;
    background-color: #f4f5f6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 537px;
    gap: 20px;
    &.has-lti {
      margin-top: 70px;
      min-height: calc(100vh - 70px);
    }
    img {
      width: 240px;
    }
    div {
      font-size: 28px;
      font-weight: 600;
    }
  }
}
