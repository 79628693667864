.verify-email {
  .verify-email_top {
    .logo {
      background-color: #47ad64;
      padding: 30px;
    }
    .background-green {
      background-color: #47ad64;
      color: #fff;
      font-size: 45px;
      text-align: center;
      padding: 100px 0 250px 0;
    }
    .background-fff {
      height: 100px;
      position: relative;
      .content {
        position: absolute;
        top: 50%; /* Đưa phần tử lên giữa theo chiều dọc */
        left: 50%; /* Đưa phần tử về giữa theo chiều ngang */
        transform: translate(-50%, -50%);
        top: -50%;
        background-color: #fff;
        right: auto;
        box-shadow: 0 0 1.875em 0 rgba(0, 0, 0, 0.14);
        border-radius: 5px;
        display: flex;
        padding: 60px 0;
        width: 80%;
        justify-content: center;
        gap: 20px;
        .email-icon {
          font-size: 60px;
        }

        .content-text {
          .title-content {
            font-size: 18px;
            line-height: 31px;
            color: #121212;
            margin-bottom: 7px;
          }
          .subtitle-content {
            color: #636b6f;
            font-size: 15px;
            line-height: 22px;
            .text-bold {
              font-weight: 700;
              color: #000;
            }
          }
        }
      }
    }
  }
  .verify-email_bottom {
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #172939;
    text-align: center;
    .title {
      font-size: 28px;
      color: #fff;
    }
    .subtitle {
      font-size: 15px;
      color: #859dad;
    }
    .two-icons {
      color: #86c0f9;
      display: flex;
      justify-content: center;
      gap: 20px;
      .icon {
        font-size: 40px;
      }
    }

    .privacy {
      font-size: 15px;
      color: #86c0f9;
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .verify-email {
    .verify-email_top {
      .logo {
      }
      .background-green {
      }
      .background-fff {
        .content {
          .email-icon {
            font-size: 60px;
          }

          .content-text {
            .title-content {
            }
            .subtitle-content {
              .text-bold {
              }
            }
          }
        }
      }
    }
    .verify-email_bottom {
      .title {
      }
      .subtitle {
      }
      .two-icons {
        .icon {
        }
      }

      .privacy {
      }
    }
  }
}

@media (max-width: 767px) {
  .verify-email {
    .verify-email_top {
      .logo {
      }
      .background-green {
        padding: 50px 0 160px 0;
      }
      .background-fff {
        height: 300px;
        .content {
          top: 30%;
          flex-direction: column;
          padding: 10px 20px;

          .email-icon {
            font-size: 60px;
          }

          .content-text {
            .title-content {
            }
            .subtitle-content {
              .text-bold {
              }
            }
          }
        }
      }
    }
    .verify-email_bottom {
      .title {
        font-size: 20px;
      }
      .subtitle {
      }
      .two-icons {
        .icon {
        }
      }

      .privacy {
      }
    }
  }
}
