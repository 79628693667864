.homepagecontent_layout {
  font-family: Poppins-Regular;
  display: flex;
  flex-direction: column;
  padding: 35px;
  gap: 50px;
  .homepagecontent_top {
    color: #186df7;
    font-size: 14px;
    font-weight: 800;
  }
}

@media (min-width: 768px) and (max-width: 1023px){
  .homepagecontent_layout{
    padding: 20px 10px;
    gap:30px;
  }
}

@media  (max-width: 767px){
  .homepagecontent_layout{
    padding: 20px 10px;
    gap:20px;
  }
}
