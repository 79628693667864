.toolbar_extend {
  margin-top: 60px;
  font-family: Poppins-Regular;
  display: flex;
  align-items: center;
  padding: 8px 18px 0 18px;
  .toolbar_reponsive-title {
    font-weight: 600;
  }
}

.link {
  color: #000;
  text-decoration: none;
}

@media screen and (max-width: 1023px) {
  .toolbar_extend {
    background-color: #f4f5f6;
    margin-left: 0;
  }
}
@media screen and (max-width: 575.98px) {
  .text-mb {
    display: none;
  }
}

/*
.toolbar_extend {
  margin-top: 110px;
  margin-left: 210.5px;
  border-left: 1px solid #e2e5ee;
  font-family: Poppins-Regular;
  .toolbar_reponsive {
    display: none;
  }
  // .toolbar_container {
  //   display: flex;
  //   border-bottom: 1px solid #e2e5ee;
  //   background-color: #f4f5f6;
  //   height: 88px;

  //   // .toolbar_item1 {
  //   //   width: 210.5px;
  //   //   border-right: 1px solid #e2e5ee;
  //   //   display: flex;
  //   //   justify-content: center;
  //   //   align-items: center;
  //   //   font-size: 17px;
  //   //   font-weight: 700;
  //   // }
  //   .toolbar_item2 {
  //     padding: 16px 28px;
  //     flex: 1;

  //     display: flex;
  //     justify-content: space-between;
  //     .toolbar_searchinput-filter {
  //       display: flex;
  //       flex: 4;
  //       align-items: center;

  //       .toolbar_search-input {
  //         display: flex;
  //         align-items: center;
  //         width: 70%;
  //         margin-right: 15px;
  //         .toolbar_input {
  //           width: 90%;
  //           border: 2px solid #e2e5ee;
  //           height: 42px;
  //           text-indent: 20px;
  //         }
  //         input::placeholder {
  //           color: rgb(167, 165, 165);
  //           font-size: 18px;
  //           line-height: normal;
  //         }
  //         .toolbar_search {
  //           display: flex;
  //           justify-content: center;
  //           align-items: center;
  //           width: 52px;
  //           height: 44px;
  //           border: 2px solid #e2e5ee;
  //         }
  //         .toolbar_search:hover {
  //           cursor: pointer;
  //           background-color: #e7e9ee;
  //         }
  //       }
  //       .toolbar_filter {
  //         display: flex;
  //         justify-content: center;
  //         align-items: center;
  //         border: 2px solid #e2e5ee;
  //         width: 63px;
  //         height: 44px;
  //         .FilterAlt {
  //           color: #3666ee;
  //         }
  //       }
  //     }
  //     .toolbar_sort {
  //       flex: 1;
  //       font-size: 14;
  //       font-weight: 600;
  //       align-items: center;

  //       display: flex;
  //       .sortby {
  //         color: rgb(69, 68, 68);
  //         padding-right: 5px;
  //       }
  //       .sortby-reponsive {
  //         display: none;
  //       }
  //     }
  //     .toolbar_sort-hidden {
  //       display: none;
  //       flex: 1;
  //     }
  //   }
  // }
}

.link {
  color: #000;
  text-decoration: none;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .toolbar_extend {
    background-color: #f4f5f6;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    .toolbar_reponsive {
      display: flex;
      align-items: center;
      padding: 8px 18px 0 18px;
      .toolbar_reponsive-title {
        font-weight: 600;
      }
    }
    // .toolbar_container {
    //   // .toolbar_item1 {
    //   //   display: none;
    //   // }
    //   .toolbar_item2 {
    //     padding: 10px 18px;

    //     .toolbar_searchinput-filter {
    //       flex: 6;
    //       .toolbar_search-input {
    //         .toolbar_input {
    //         }
    //         input::placeholder {
    //           font-size: 15px;
    //           line-height: normal;
    //         }
    //       }
    //     }

    //     .sortby {
    //       display: none;
    //     }
    //     .sortby-reponsive {
    //       display: flex;
    //       align-items: center;
    //     }
    //     .sortby-display {
    //       display: none;
    //     }
    //   }
    // }
  }
}

@media (max-width: 767px) {
  .toolbar_extend {
    background-color: #f4f5f6;
    display: flex;
    flex-direction: column;
    margin-left: 0;
    .toolbar_reponsive {
      display: flex;
      align-items: center;
      padding: 8px 9px 0 9px;
      .toolbar_reponsive-title {
        font-weight: 600;
      }
    }
    // .toolbar_container {
    //   // .toolbar_item1 {
    //   //   display: none;
    //   // }
    //   .toolbar_item2 {
    //     padding: 8px 9px;
    //     padding-top: 0;
    //     .toolbar_searchinput-filter {
    //       .toolbar_search-input {
    //         .toolbar_input {
    //           text-indent: 5px;
    //         }
    //         input::placeholder {
    //           font-size: 12px;
    //           line-height: normal;
    //         }
    //       }
    //     }
    //     .sortby {
    //       display: none;
    //     }
    //     .sortby-reponsive {
    //       display: flex;
    //       align-items: center;
    //     }
    //     .sortby-display {
    //       display: none;
    //     }
    //   }
    // }
  }
}
*/
