.navbar-content {
  margin-top: 45px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  height: 65px;
  border-left: 1px solid #e2e5ee;
  border-right: 1px solid #e2e5ee;
  border-bottom: 1px solid #e2e5ee;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  &.has-lti {
    margin-top: 0;
  }
  .left {
    display: flex;
    align-items: center;
    gap: 30px;

    > div {
      display: flex;
      gap: 10px;
      align-items: center;
      line-height: 65px;
      padding: 0 20px;
      &:hover {
        cursor: pointer;
      }
    }
    .folder-button {
      box-shadow: none;
      background-color: #fff;
      color: #3c4859;
      .folder-icon {
        font-size: 30px;
      }
    }

    .active {
      border-bottom: 3px solid #186df7;
    }
    .no-active {
      color: #757575;
    }
  }
  .right {
    display: flex;
    gap: 20px;
    align-items: center;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .navbar-content {
    .left {
      > div {
        &:hover {
        }
      }
      .folder-button {
        .folder-icon {
        }
      }
      .active {
      }
      .no-active {
      }
    }
    .right {
    }
  }
}

@media (min-width: 308px) and (max-width: 767px) {
  .navbar-content {
    padding: 0 5px;
    .left {
      gap: 0px;
      > div {
        padding: 0 5px;

        &:hover {
        }
      }
      .folder-button {
        .folder-icon {
        }
      }
      .active {
      }
      .no-active {
      }
    }
    .right {
      // display: none;
      gap: 0px;
    }
  }
}

@media (max-width: 307px) {
  .navbar-content {
    padding: 0 5px;
    .left {
      gap: 0px;
      font-size: 12px !important;
      > div {
        padding: 0 5px;

        &:hover {
        }
      }
      .folder-button {
        .folder-icon {
        }
      }
      .active {
      }
      .no-active {
      }
    }
    .right {
      // display: none;
      gap: 0px;
    }
  }
}
