.emptyfolder_layout {
  display: flex;
  flex-direction: column;

  img {
    width: 70px;
  }
  .empty-title {
    font-size: 25px;
    line-height: 39px;
    font-weight: 700;
    color: #2e2e2e;
    margin-top: 30px;
  }
  .empty-subtitle {
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    color: #636b6f;
    margin-top: 8px;
  }
}
