.settings_container {
  .accordion {
    .accordionsumary .MuiAccordionSummary-content {
      flex-direction: column;
      .item-title {
        font-weight: 600;
        font-size: 18px;
        font-family: Poppins-Regular;
      }
      .item-subtitle {
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
      }
    }

    .archived {
      display: flex;
      margin: 10px 0;
      justify-content: space-between;

      align-items: center;
      justify-content: flex-start;
      .archived-title {
        flex: 1;
        font-size: 14px;
        font-weight: 700;
      }
      .archived-dropdown {
        flex: 6;
      }
      .archived-dropdown {
        .MuiSelect-select {
          min-width: 200px;
        }
        .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
          top: -9px;
        }
      }
    }
  }
}

.button_div {
  margin-top: 30px;
  .button {
    text-transform: none;
    background-color: #186df7;
    border-radius: 2px;
  }
  .button:hover {
    background-color: #1662de;
  }
}
