.password_page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .password_layout {
    border: 2px solid #e2e5ee;
    display: flex;
    gap: 16px;
    width: 400px;
    padding: 50px;
    .title {
      font-weight: 700;
    }
    .title-back {
      display: flex;
      align-items: center;
      gap: 10px;
      .arrowbackoutlined-icon {
        &:hover {
          cursor: pointer;
        }
      }
      .title-text {
        font-weight: 700;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .password_page {
    .password_layout {
      .title {
      }
    }
  }
}

@media (max-width: 767px) {
  .password_page {
    .password_layout {
      padding: 20px;
      border: none;

      .title {
      }
    }
  }
}
