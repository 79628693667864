.view-info {
  padding: 30px 50px;
  display: flex;

  justify-content: space-between;
  .left {
    display: flex;
    img {
      max-width: 320px;
      max-height: 300px;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 500px;
    .right-item {
      border-bottom: 1px solid #ddd;
      display: flex;
      gap: 100px;
      justify-content: space-between;
      font-size: 14px;
      align-items: center;
      div {
        font-weight: 700;
        line-height: 27px;
      }
      p {
        font-weight: 400;
        color: #3c4859;
      }
    }
    .right-button {
      .button-edit {
        text-transform: none;
      }
    }
  }
}

.save-button:hover {
}

#demo-simple-select-label {
  z-index: 100;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .view-info {
    padding: 30px 100px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    .left {
      justify-content: center;
    }
    .right {
      .right-item {
      }
    }
  }
  .edit-info {
  }
}

@media (max-width: 767px) {
  .view-info {
    padding: 0px 0px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .left {
      justify-content: center;
    }
    .right {
      width: 330px;
      gap: 20px;
      .right-item {
        font-size: 12px;
        gap: 30px;
      }
    }
  }
  .edit-info {
  }
}
