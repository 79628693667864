// :hover {
//   cursor: pointer;
//   text-decoration: underline;
//   text-underline-offset: 10px;
//   text-decoration-color: #fff;
//   -moz-text-decoration-color: #fff;
//   text-decoration-thickness: 2px;
// }
.link {
  text-decoration: none;
  color: #000;
}
