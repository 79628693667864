.connectLMS_bottom {
  .initial {
    display: flex;
    flex-direction: column;
    gap: 15px;
    .text-normal {
      font-size: 15px;
      line-height: 22px;
      color: #636b6f;
      font-weight: 400;
    }
    .dropdown {
      width: 50%;
    }
  }
}

.add-connection-wrapper {
  width: 50%;
}

.connectLMS-list {
  &__item {
    .header {
      padding-top: 1.5em;
      .lti-name {
        color: #000;
        font-size: 1.3em;
      }
      .lti-help {
        display: flex;
        align-items: center;
        width: fit-content;
        margin-top: 1em;
        padding: 0.5em;

        background: #dbf6ff;
        font-size: 0.9em;
        color: #31708f;
        border-radius: 0.2em;

        a {
          color: blue;
          cursor: pointer;
        }
        svg {
          margin-right: 8px;
        }
      }
    }

    .connection-fields {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 2em;
      margin-top: 0.5em;

      table {
        font-size: 0.9em;
        width: 100%;
        border-collapse: collapse;
      }
      tr {
        border-top: 1px solid #e1e3e8;
        &:nth-of-type(odd) {
          background-color: #f9f9f9;
        }
      }
      th {
        width: 10em;
        font-weight: bold;
        text-align: left;
        padding: 0.3em 1em 0.3em 0.3em;
      }
      td {
        border-top: none;
        padding: 1em 0;
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .actions {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
    }
  }
}

.important-info-wrapper {
  border: 1px solid #e2e5ee;
  padding: 1.5em 2em;
  font-size: 14px;
  line-height: 1.6;
  color: #636b6f;

  .header {
    color: #3c4859;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 8px;
  }
  .text {
    text-decoration: underline;
    font-weight: bold;
    margin-top: 12px;
  }
  .unordered-lst {
    // list-style-type: disc;
    padding-left: 30px;
  }
  .list-item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
  }
  .circle {
    min-width: 6px;
    width: 6px;
    min-height: 6px;
    height: 6px;
    background-color: black;
    border-radius: 50%;
  }
  .link {
    font-weight: 800;
  }
}

.save-btn.MuiButtonBase-root {
  margin-top: 16px;
}
.add-lms-connection-modal {
  .MuiTypography-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;
  }
  svg {
    cursor: pointer;
  }
}
.connection-settings-modal {
  .MuiTypography-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;
  }
  svg {
    cursor: pointer;
  }
}

.connection-settings-btn{
  padding: 0.5em;
  padding-left:0;
  font-weight: 900;
}

.regenerate-keys-btn{
  padding: 0.5em;
  padding-left:0;
  font-weight: 900;
}

.functions-wrapper{
  margin-top: 1em;
  margin-bottom: 2em;
}

.connection-setting-save-btn{
  margin-top:1em;
  margin-bottom:2em
}

@media screen and (max-width: 767.98px) {
  .connection-settings-modal {
    background-color: white;
  }
  .connectLMS-list {
    &__item {
      .connection-fields {
        flex-direction: column-reverse;
        .actions {
          flex-direction: row;
          flex-wrap: wrap;
        }
      }
    }
  }

  .important-info-wrapper {
    padding: 1em;
    .unordered-lst {
      padding-left: 4px;
    }
  }

  .add-lms-connection-modal {
    background-color: white;
    // .MuiBox-root {
    //   width: 75%;
    // }
  }
}
@media screen and (max-width: 575.98px) {
  .connectLMS-list {
    &__item {
      .connection-fields {
        th {
          width: 8em;
        }
      }
    }
  }
}
