.cohota_component {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard syntax */
  font-family: Poppins-Regular;
  display: flex;
  align-items: center;
  gap: 6px;
  .logo-div {
    position: relative;
    width: 25px;
    height: 42px;
    .logo-icon {
      position: absolute;
      top: -5px;
      right: -29px;
      width: 90px;
    }
  }
  .cohota-text {
    font-size: 25px;
    font-weight: 400;
    text-decoration: none;
  }
}
