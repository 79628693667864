.landingpage_container {
  font-family: Poppins-Regular;
  .landingpage_heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    gap: 15px;
    .landingpage_heading-title {
      color: #181c47;
      font-size: 35px;
      font-weight: 900;
    }
    .landingpage_heading-description {
      font-size: 20px;
      font-weight: 400;
      text-align: center;
    }
  }
  .landingpage_LMSIntegerations {
    padding: 112px;
    background-color: #ce7b91;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: #fff;

    align-items: center;
    .LMS_title {
      font-family: Poppins-ExtraBold;
      font-size: 37px;
      text-align: center;
    }
    .LMS_subtitle {
      margin-top: 30px;
      font-size: 23px;
      font-weight: 500;
      text-align: center;
      line-height: 30px;
    }
    .list_logo {
      display: flex;
      gap: 40px;
      align-items: center;
      padding: 48px 0;
      .logo-img {
        max-width: 150px;
        max-height: 50px;
      }
    }
    .landingpage_button {
      .button {
        text-transform: none;
        font-size: 18px;
        font-weight: 600;
        background-color: #782d41;
        padding: 18px 36px;
      }
      .button:hover {
        background-color: #5e2333;
      }
    }
  }

  .landingpage_subfooter {
    background-color: #5463d5;
    display: flex;
    padding: 100px 100px 100px 0;

    .landingpage_image {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 70%;
      }
    }
    .landingpage_right {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 30px;
      color: #fff;
      .right-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 30px;
        .box {
          width: 300px;
          height: 350px;
          background-color: #1d2777;
          border-radius: 4px;
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 10px;
          align-items: center;
          .box-icon {
            font-size: 80px;
          }
          .box-number {
            line-height: 124px;
            font-size: 82px;
            font-weight: 700;
          }
          .box-text {
            font-weight: 400;
            font-size: 32px;
            line-height: 48px;
            text-align: center;
          }
        }
        .in {
          font-size: 27px;
          font-weight: 700;
          line-height: 41px;
        }
      }
      .right-bottom {
        font-size: 27px;
        font-weight: 400;
        line-height: 41px;

        text-align: center;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .landingpage_container {
    .landingpage_heading {
      padding: 25px;
      gap: 15px;
      .landingpage_heading-title {
        font-size: 35px;
        font-weight: 900;
      }
      .landingpage_heading-description {
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        line-height: 1.5;
      }
    }
    .landingpage_LMSIntegerations {
      padding: 90px;
      .LMS_title {
      }
      .LMS_subtitle {
      }
      .list_logo {
        gap: 30px;
        .logo-img {
          max-width: 90px;
          max-height: 30px;
        }
      }
      .landingpage_button {
      }
    }
    .landingpage_subfooter {
      flex-direction: column;
      padding: 50px;
      .landingpage_image {
      }
      .landingpage_right {
        .right-top {
          .box {
          }
          .in {
          }
        }
        .right-bottom {
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .landingpage_container {
    .landingpage_heading {
padding-left:8px;
      .landingpage_heading-title {
      }
      .landingpage_heading-description {
      }
    }
    .landingpage_LMSIntegerations {
      padding: 40px;
      .LMS_title {
        font-size: 32px;
      }
      .LMS_subtitle {
        font-size: 20px;
      }
      .list_logo {
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 48px 10px;
        .logo-img {
          max-width: 100px;
          max-height: 30px;
        }
      }
      .landingpage_button {
        .button {
          font-size: 19px !important;
        }
      }
    }
    .landingpage_subfooter {
      flex-direction: column;
      padding: 30px;
      .landingpage_image {
      }
      .landingpage_right {
        .right-top {
          gap: 10px;
          .box {
            height: 200px;
            gap: 8px;
            .box-icon {
              font-size: 50px;
            }
            .box-number {
              line-height: 50px;
              font-size: 30px;
              font-weight: 500;
            }
            .box-text {
              font-weight: 300;
              font-size: 16px;
              line-height: 26px;
            }
          }
          .in {
            font-size: 20px;
          }
        }
        .right-bottom {
          font-size: 18px;
          line-height: 30px;
        }
      }
    }
  }
}
