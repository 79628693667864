.loginform_layout {
  border: 2px solid #e2e5ee;

  @media (min-width: 1024px) {
    margin: 0 30%;
    padding: 3%;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    margin: 0 20%;
    padding: 3%;
  }

  @media (max-width: 767px) {
    border: none;
    margin: 5%;
    padding: 0;
  }
  display: flex;
  gap: 16px;
  .title {
    font-weight: 700;
  }

  .title-back {
    display: flex;
    align-items: center;
    gap: 10px;
    .arrowbackoutlined-icon {
      &:hover {
        cursor: pointer;
      }
    }
    .title-text {
      font-weight: 700;
    }
  }

  .input {
  }

  .hr {
    color: #e2e5ee;
  }

  .item {
    display: flex;
    .linkblue {
      color: #186df7;
    }
    .title {
      text-transform: none;
    }
    .linkblue,
    .title {
      cursor: pointer;
    }
  }
  .item1 {
    justify-content: space-between;
    align-items: center;
  }
  .item2 {
    flex-direction: column;
  }
}
