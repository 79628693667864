.input_component {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #3c4859;

  .label {
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
  }

  .input_container {
    display: flex;
    align-items: center;
    position: relative;
    .input {
      flex: 1;
      padding: 15px 10px;
      border-radius: 5px;
      font-size: 14px;
      border: 2px solid #e2e5ee;
      transition: border-color 0.3s, box-shadow 0.3s; // Thêm transition
      &:focus {
        outline: none;
        border: 2px solid #98cbe8;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
          0 0 8px rgba(152, 203, 232, 0.6);
      }
    }
    .eye-icon {
      position: absolute;
      right: 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
