.copy-clipboard-wrapper {
  display: flex;
  align-items: center;
  background: #fff;
  color: #186df7;

  border: 1px solid #186df7;
  border-radius: 0.208em;
  font-weight: 700;
  font-size: 0.83em;
  cursor: pointer;
}
  