.page_401 {
    padding: 0 0 40px 0;
    background: #fff;
    font-family: "Arvo", serif;
  }
  
  .page_401 img {
    width: 100%;
  }
  
  .four_zero_four_bg401 {
    background-image: url('../../assets/img/401.svg');
    background-repeat: no-repeat;
    height: 400px;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    h1 {
      font-size: 80px;
    }
    h3 {
      font-size: 80px;
    }
  }
  
  .link_401 {
    color: #fff !important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;
    text-decoration: none;

    &:hover{
      cursor: pointer;
    }
  }
  .contant_box_401 {
    margin-top: 0px;
    line-height: 1.5;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  /* Center align text */
.text-center {
    text-align: center;
  }
  
  /* Offset columns in small devices (tablets) */
  .col-sm-offset-1 {
    margin-left: 8.3333333333%;
  }
  
  /* 10-column wide columns in small devices (tablets) */
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  
  /* Fixed-width container with responsive padding */
  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  
  /* 12-column wide columns in small devices (tablets) */
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  
  