.headerpage_layout {
  display: flex;
  position: fixed;
  z-index: 200;
  top: 0;
  right: 0;
  left: 0;
  height: 45px;
  background-color: #3c4859;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  color: #fff;
  font-size: 15px;
  line-height: 37px;
  font-family: Poppins-Regular;
  .headerpage_item {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;

    line-height: 42px;
    .headerpage_itemchild {
      display: flex;
      gap: 20px;
      align-items: center;
      div:hover {
        color: #8fc7ff;
        cursor: pointer;
      }
      .link {
        color: #fff;
        font-size: 15px;
        text-decoration: none;
        div {
          padding: 0 20px;
        }
      }
      .active {
        color: #8fc7ff;
        border-bottom: 3px solid;
      }
    }
    .headerpage_item-responsive {
      display: none;
      .menu-responsive {
        div {
          color: #fff;
        }
      }
    }

    .logo {
      width: 42px;
      height: 22px;
    }

    .headerpage_button {
      border-radius: 0px;
      height: 45px;
      text-transform: none;
      font-family: Poppins-Regular;
      box-shadow: none;
      background-color: #186df7;
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .headerpage_layout {
    .headerpage_item {
      .headerpage_itemchild {
        display: none;
      }
      .headerpage_item-responsive {
        :hover {
          cursor: pointer;
        }
        display: flex;
        position: relative;
        .menu-responsive {
          position: absolute;
          background-color: #3c4859;
          top: 35px;
          left: 2px;
          min-width: 250px;
          div {
            padding: 5px 10px;
            text-align: center;
          }
          div:hover {
            cursor: pointer;
            background-color: #000;
            color: #8fc7ff;
          }
        }
      }

      .logo {
      }

      .headerpage_button {
      }
    }
  }
}

@media (min-width: 355px) and (max-width: 767px) {
  .headerpage_layout {
    .headerpage_item {
      .headerpage_itemchild {
        display: none;
      }
      .headerpage_item-responsive {
        :hover {
          cursor: pointer;
        }
        display: flex;
        position: relative;
        .menu-responsive {
          font-size: 14px;
          position: absolute;
          background-color: #3c4859;
          top: 35px;
          left: 2px;
          min-width: 230px;
          div {
            padding: 3px 8px;
            text-align: center;
          }
          div:hover {
            cursor: pointer;
            background-color: #000;
            color: #8fc7ff;
          }
        }
      }

      .logo {
      }

      .headerpage_button {
      }
    }
  }
}


@media (max-width: 354px) {
  .headerpage_layout {
    .headerpage_item {
      gap:10px;
      .headerpage_itemchild {
        display: none;
      }
      .headerpage_item-responsive {
        :hover {
          cursor: pointer;
        }
        display: flex;
        position: relative;
        .menu-responsive {
          font-size: 14px;
          position: absolute;
          background-color: #3c4859;
          top: 35px;
          left: 2px;
          min-width: 230px;
          div {
            padding: 3px 8px;
            text-align: center;
          }
          div:hover {
            cursor: pointer;
            background-color: #000;
            color: #8fc7ff;
          }
        }
      }

      .logo {
      }

      .headerpage_button {
        display: none;
        font-size: 12px !important;
      }
    }
  }
}
