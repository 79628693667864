.manage-content {
  margin: auto;
  width: 50%;

  .content-area {
    margin-top: 140px;
    margin-bottom: 30px;

    .two-buttons {
      margin-top: 20px;
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }
}


.lti {
  .content-area-author {
    margin-top: 140px;
    margin-bottom: 30px;

    .two-buttons {
      margin-top: 20px;
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }

  .content-area-learner {
    margin-top: 10px;
    margin-bottom: 0px;
  }
}

@media (min-width: 768px) and (max-width: 950px) {
  .manage-content {
    padding: 0 10px;
    width: 98%;

    .content-area {
      .two-buttons {}
    }
  }
}

@media (max-width: 767px) {
  .manage-content {
    padding: 0 20px;
    width: 100%;

    .content-area {
      .two-buttons {}
    }
  }
}