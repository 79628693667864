//SCSS OTHER COMPONENT
.menu_component {
  .menu_button {
    background-color: #3c4859;
    text-transform: none;
  }
  .menu_button:hover {
    background-color: #3c4859;
  }
}

.link {
  text-decoration: none;
  color: #000;
}
