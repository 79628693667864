.slider_container {
  background-color: #f2f4fc;
  display: flex;
  gap:20px;
  font-family: Poppins-Regular;
  align-items: center;
  padding: 80px;

  // (margin fixed)
  margin-top: 80px;

  .slider_item {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  .slider_item1 {
    flex-direction: column;
    gap: 35px;
    .title {
      font-size: 44px;
      font-weight: 750;
      color: #1e213b;
    }
    .subtitle {
      font-weight: 400;
      font-family: Poppins-Regular;
    }
    .buttons {
      display: flex;

      gap: 10px;

      .button {
        height: 50px;
        font-size: 16px;
        font-weight: 600;
        text-transform: none;
      }
      .button1 {
        background-color: #4d5abd;
      font-family: Poppins-Regular;

      }
      .button2 {
        color: #4d5abd;
        border: #4d5abd 2.5px solid;
        background-color: #fff;
      font-family: Poppins-Regular;

      }
    }
    .question {
      font-weight: 600;
      font-size: 16px;
      text-decoration: underline;
      font-family: Poppins-Regular;

    }
    .question:hover {
      cursor: pointer;
    }
  }
  .slider_item2 {
    .image {
      border-radius: 90px 0;
      width: 100%;
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .slider_container {
    flex-direction: column-reverse;
    padding: 40px 40px;
  }
}

@media (max-width: 767px) {
  .slider_container {
    flex-direction: column-reverse;
    padding: 20px 10px;
  }

  .button {
    font-size: 12px !important;
  }

  .buttons {
    justify-content: center;
  }
}
