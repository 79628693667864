.navbar_container {
  margin-top: 110px;
  position: fixed;
  top: 0;
  width: 210px;
  display: flex;
  height: 100vh;
  flex-direction: column;
  border: 1px solid #e2e5ee;
  background-color: #f4f5f6;
  font-family: Poppins-Regular;
  .navbar_title {
    width: 210px;
    border-right: 1px solid #e2e5ee;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    font-weight: 700;
    background-color: #f4f5f6;
    height: 88px;
    border-bottom: 1px solid #e2e5ee;
  }

  .navbar_item {
    height: 45px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 14px;
    font-weight: 700;
  }

  .navbar_item:hover {
    background-color: #5d5d5d;
    cursor: pointer;
    color: #fff;
  }
  .navbar_item.active {
    border-left: 5px solid black;
    background-color: #5d5d5d;
    div {
      color: #fff;
    }
  }
}

@media (max-width: 1023px) {
  .navbar_container {
    display: none;
  }
}
