.back-wrapper {
  // margin-top: 45px;
  height: 50px;
  position: fixed;
  background-color: #fff;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 10px 25px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  z-index: 1;

  .button-back {
    text-transform: none;
    background-color: #fff;
    color: #3c4859;
    font-size: 14px;
    font-weight: 700;
    box-shadow: none;
  }
  .button-back:hover {
    background-color: #e7e9ee;
  }
}
