.subheader_container-extend {
  margin-top: 45px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  height: 65px;
  border-left: 1px solid #e2e5ee;
  border-right: 1px solid #e2e5ee;
  border-bottom: 1px solid #e2e5ee;
  background-color: #fff;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  &.has-lti {
    margin-top: 0;
  }
  .upload {
    height: 65px;
    width: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #e2e5ee;
  }
  .button {
    text-transform: none;
    background-color: #186df7;
    color: #fff;
    font-family: Poppins-Regular;
    font-size: 14px;
    font-weight: 600;
  }
  .button:hover {
    background-color: #1662de;
  }
  .button-uploadlogo {
    text-transform: none;
    border: 1px dashed #cdd5e2;
    background-color: #fff;
    color: #3c4859;
  }
  .button-uploadlogo:hover {
    background-color: #f4f5f6;
  }
  .subheader_container {
    height: 65px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    padding-left: 15px;
    justify-content: space-between;
    .add-btn {
      margin-right: 8px;
    }
    .button-newfolder {
      border: 1px solid #e2e5ee;
      background-color: #f4f5f6;
      color: #3c4859;
      box-shadow: none;
      box-sizing: border-box;
    }
    .button-newfolder:hover {
      background-color: #e7e9ee;
    }
    &--left {
      display: flex;
    }
  }
}

@media (min-width: 355px) and (max-width: 1023px) {
  .subheader_container-extend {
    .upload {
      display: none;
    }
  }
}

@media (max-width: 354px) {
  .subheader_container-extend {
    .upload {
      display: none;
    }
    .subheader_container {
      gap: 5px;
      padding-left: 5px;
      .MuiButtonBase-root .MuiButton-startIcon.css-htszrh-MuiButton-startIcon {
        margin: 0;
      }
    }
  }
}
