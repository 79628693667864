.signup_page {
  font-family: Poppins-Regular;
  .signup_background {
    color: #fff;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 50px;
    background-color: #5463d5;
    padding: 130px 0;
    margin-top: 80px;
    .title {
      font-family: Poppins-ExtraBold;
      font-size: 44px;
      font-weight: 700;
      line-height: 50px;
    }
    .subtitle {
      font-size: 20px;
      font-weight: 400;
      line-height: 27px;
    }
    div {
      .signup_button {
        text-transform: none;
        padding: 16px 38px;
        background-color: #7b1fa2;
        font-size: 18px;
        font-weight: 600;
      }
      .signup_button:hover {
        background-color: #651b85;
      }
    }
  }

  .signup_background-green {
    background-color: #218380;
    margin-top: 0;
  }

  .form-submit {
    padding: 100px;
    position: relative;
    .form-title {
      top: 85px;
      padding: 0px 10px;
      margin-left: 12px;
      font-size: 23px;
      line-height: 33px;
      font-family: Poppins-ExtraBold;
      background: #fff;
      position: absolute;
      display: flex;
      justify-content: center;
    }
    .signup_form-submit {
      border: 2px solid #dde0eb;
      display: flex;
      flex-direction: column;
      gap: 50px;
      padding: 50px;
      .form {
        display: flex;
        flex-direction: column;
        gap: 50px;
        .form-item {
          display: flex;
          justify-content: space-between;
          .Field {
            min-width: 45%;
            .input {
              width: 100%;
            }
          }
        }
      }
      .submit {
        display: flex;
        flex-direction: column;
        gap: 30px;
        .warning {
          display: flex;
          align-items: center;
          gap: 10px;
          .warning-icon {
            font-size: 40px;
            color: #5463d5;
          }
          .warning-text {
            line-height: 27px;
          }
        }
        .info {
          background-color: #cef3f2;
          color: #145250;
          padding: 16px 32px;
          line-height: 1.5;
        }
        .button {
          text-align: center;
          .button-start {
            text-transform: none;
            background-color: #5463d5;
            padding: 16px 32px;
            font-size: 18px;
            font-weight: 600;
          }
          .button-start:hover {
            background-color: #4d5abd;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .signup_page {
    .signup_background {
      padding: 100px 0;
      gap: 40px;
      .title {
        font-size: 35px;
      }
      .subtitle {
        font-size: 18px;
      }
    }
    .form-submit {
      padding: 100px 40px;
      margin: -20px 0;
      .signup_form-submit {
        padding: 40px 20px;
        .form {
          gap: 20px;
          .form-item {
            flex-direction: column;
            gap: 20px;
          }
        }
        .submit {
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .signup_page {
    .signup_background {
      padding: 80px 0;
      gap: 30px;
      .title {
        font-size: 23px;
        line-height: 1.5;
      }
      .subtitle {
        font-size: 16px;
      }
    }
    .form-submit {
      padding: 100px 10px;
      margin: -50px 0;
      .form-title {
        font-size: 16px;
      }
      .signup_form-submit {
        padding: 40px 10px;
        .form {
          gap: 15px;
          .form-item {
            flex-direction: column;
            gap: 15px;
          }
        }
        .submit {
        }
      }
    }
  }
}
