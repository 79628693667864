.aboutroles_table {
  .TableRow {
    .column {
      width: 73px;
      text-align: center;
    }
  }
  .TableCell1 {
    .title {
      font-size: 16px;
      line-height: 26px;
      font-weight: 700;
      color: #3c4859;
    }
    .subtitle {
      font-size: 14px;
      line-height: 26px;
      color: #757575;
      font-weight: 400;
    }
  }
  .TableCell2 {
    text-align: center;
    .true {
      color: #52993c;
      font-size: 32px;
      text-align: center;
    }
    .false {
      color: #e03d28;
      font-size: 32px;
    }
  }
}
